console.log("%c" + "Hold Up!", "color: #7289DA; -webkit-text-stroke: 2px black; font-size: 72px; font-weight: bold;");
require('./bootstrap');

// import { Autoplay, Navigation, Pagination } from "swiper";
// import Swiper from "swiper";
// Swiper.use([Autoplay, Navigation, Pagination]);

require('./jquery.nice-select');
require('./main');
require('./calc')
