// import Swiper JS
import Swiper, {Navigation, Pagination} from 'swiper';

Swiper.use([Navigation, Pagination]);

$(document).ready(function () {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    /*---background image---*/
    function dataBackgroundImage() {
        $('[data-bgimg]').each(function () {
            var bgImgUrl = $(this).data('bgimg');
            $(this).css({
                'background-image': 'url(' + bgImgUrl + ')', // + meaning concat
            });
        });
    }

    $(window).on('load', function () {
        dataBackgroundImage();
    });


    // swiper slider activation
    new Swiper('.swiper_slider', {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });

    /*swiper container activation*/
    new Swiper('.testimonial_swiper', {
        slidesPerView: 2,
        clickable: true,
        spaceBetween: 70,
        breakpoints: {
            1500: {
                spaceBetween: 70,
            },
            1200: {
                spaceBetween: 30,
            },
            992: {
                spaceBetween: 30,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            576: {
                slidesPerView: 1,
                spaceBetween: 30,
            },
            250: {
                slidesPerView: 1,
                spaceBetween: 20,
            }
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'progressbar',

        },
    });

    const shop_slider = new Swiper('.shop_swiper', {
        slidesPerView: 1,
        clickable: true,
        spaceBetween: 20,
        breakpoints: {
            576: {
                slidesPerView: "auto",
                spaceBetween: 12,
            }
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    $('.select_option').niceSelect();

    /*---mobile menu activation---*/
    $('.mobile_open').on('click', function () {
        $(this).find('#nav-icon').toggleClass('open');
        // $(this).toggleClass('mobile_close');
        $('.mobile_menu_wrapper').toggleClass('active')
        $('body').toggleClass('overflow-hidden')
    });
    /*---Off mobile Menu---*/
    var $mobileNav = $('.mobile_main_menu'),
        $mobileNavSubMenu = $mobileNav.find('.sub-menu,.sub_menu');
    $mobileNavSubMenu.parent().prepend('<span class="menu-expand"><i class="fas fa-caret-down"></i></span>');

    $mobileNavSubMenu.slideUp();

    $mobileNav.on('click', 'li a, li .menu-expand', function (e) {
        var $this = $(this);
        if (($this.parent().attr('class').match(/\b(menu-item-has-children|has-children|has-sub-menu)\b/)) && ($this.attr('href') === '#' || $this.hasClass('menu-expand'))) {
            e.preventDefault();
            if ($this.siblings('ul:visible').length) {
                $this.siblings('ul').slideUp('slow');
            } else {
                $this.closest('li').siblings('li').find('ul:visible').slideUp('slow');
                $this.siblings('ul').slideDown('slow');
            }
        }
        if ($this.is('a') || $this.is('span') || $this.attr('clas').match(/\b(menu-expand)\b/)) {
            $this.parent().toggleClass('menu-open');
        } else if ($this.is('li') && $this.attr('class').match(/\b('menu-item-has-children')\b/)) {
            $this.toggleClass('menu-open');
        }
    });


    function reveal() {
        var reveals = document.querySelectorAll(".fadeInUp");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            }
            //   else {
            //     reveals[i].classList.remove("active");
            //   }
        }
    }

    reveal()

    window.addEventListener("scroll", reveal);

    /*-- Brands slider --*/
    // breakpoint where swiper will be destroyed
    // and switches to a columns layout
    const breakpoint = window.matchMedia('(min-width:36rem)');

    // keep track of swiper instances to destroy later
    let brands_slider;

    const breakpointChecker = function () {

        if (breakpoint.matches === false) {
            if (brands_slider !== undefined) {
                brands_slider.destroy(true, true);
            }
            return;
        } else if (breakpoint.matches === true) {
            return enableSwiper();
        }
    };

    const enableSwiper = function () {
        brands_slider = new Swiper('.brands_swiper', {
            slidesPerView: 1,
            clickable: true,
            spaceBetween: 20,
            breakpoints: {
                576: {
                    slidesPerView: "auto",
                    spaceBetween: 12,
                }
            },
            navigation: {
                nextEl: '.brands-button-next',
                prevEl: '.brands-button-prev',
            },
        });

    };
    breakpoint.addEventListener("change", () => {
        breakpointChecker()
    });
    breakpointChecker();

    // Datepicker
    // $.datepicker.regional['ru'] = {
    //     closeText: 'Закрыть',
    //     prevText: '&#x3c;Пред',
    //     nextText: 'След&#x3e;',
    //     currentText: 'Сегодня',
    //     monthNames: ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
    //     monthNamesShort: ['Янв','Фев','Мар','Апр','Май','Июн','Июл','Авг','Сен','Окт','Ноя','Дек'],
    //     dayNames: ['воскресенье','понедельник','вторник','среда','четверг','пятница','суббота'],
    //     dayNamesShort: ['вск','пнд','втр','срд','чтв','птн','сбт'],
    //     dayNamesMin: ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
    //     weekHeader: 'Нед',
    //     dateFormat: 'dd/mm/yy',
    //     firstDay: 1,
    //     isRTL: false,
    //     showMonthAfterYear: false,
    //     yearSuffix: ''};
    // $.datepicker.setDefaults( $.datepicker.regional[ "ru" ] );
    // $('.datepicker').datepicker()

    // ----------------------------- My account pages --------------------------
    // Copy text to clipboard
    $('.copy-text').on('click', function (e) {
        e.preventDefault();
        var tooltip = bootstrap.Tooltip.getOrCreateInstance($(this))
        var copyText = $(this).parent().prev().text()
        navigator.clipboard.writeText(copyText);

        $('.copy-text').each(function () {
            $(this).attr('data-bs-original-title', $(this).data('title'))
        })

        $(this).attr('data-bs-original-title', 'Copied: ' + copyText)
        tooltip.show()
        $(this).on('mouseout', function () {
            tooltip.hide()
            $(this).attr('data-bs-original-title', $(this).data('title'))
        })
    });

    if ($('.forbidden-products').length && $('.working-info').length) {
        var height = $('.working-info-block').outerHeight()
        $('.forbidden-products .products').css('max-height', height)
    }

    // Tabs
    var tabEl = document.querySelectorAll('[data-bs-toggle="tab"]')
    if (tabEl.length) {
        tabEl.forEach((tab) => {
            tab.addEventListener('shown.bs.tab', (event) => {
                $(event.relatedTarget).parent().removeClass('active')
                $(event.target).parent().addClass('active')
                event.target // newly activated tab
                event.relatedTarget // previous active tab
                localStorage.setItem('lastTab', $(event.target).attr('href'));
            });
        });
        // set last tab active
        var lastTab = localStorage.getItem('lastTab');
        if (lastTab) {
            var triggerEl = document.querySelector('[href="' + lastTab + '"]')
            if (triggerEl) {
                var tab = bootstrap.Tab.getOrCreateInstance(triggerEl)
                tab.show()
            }
        }
    }
    // sidebar menu
    $('.account_sidebar_menu li').on('click', function () {
        if ($(this).closest('ul').hasClass('collapsed')) {
            $(this).closest('ul').removeClass('collapsed')
        } else {
            $(this).closest('ul').addClass('collapsed')
        }

    })

    // order tracking
    //scroll tracking to active status
    if ($('.order-track').length) {
        $('.order-track').each(function () {
            var lastActive = $(this).find('.order-track-step.active').last().offset().top
            var blockTop = $(this).offset().top
            $(this).animate({
                scrollTop: lastActive - blockTop - 5
            }, 400);
        })
    }

    // pickup point change
    $('li.pick_up_point input[type="radio"]').change(function (e) {
        let office = $(this).val()
        $.ajax({
            type: 'POST',
            url: '/account/change-user-pick-up-point',
            data: {param: office},
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            success: function (data) {
                console.log(data)
            },
            error: function (xhr) {
                if (xhr.status == 500) {
                    window.location.reload()
                }
            },
        });
    })


//})

// Received orders
//$(document).ready(function () {
    var table = $('#received').DataTable(
        {
            "pageLength": 10,
            //https://legacy.datatables.net/ref#sDom
            "sDom": "tr" + "<'row m-0 pt-3 pb-4'<'col-md-4 text-center text-md-start mb-2 order-2 order-md-0' B><'col-md-4 text-center  mb-2'p><'col-md-4 text-center text-md-end  mb-2'i>>", //https://datatables.net/forums/discussion/44764
            "scrollX": true,
            "language": { //https://datatables.net/reference/option/language
                "info": "Showed _START_-_END_ of _TOTAL_",
                "infoEmpty": "Showing 0 to 0 of 0",
                "infoFiltered": "(filtered from _MAX_ total)",
                "paginate": {
                    "next": '<i class="fas fa-chevron-right"></i>',
                    "previous": '<i class="fas fa-chevron-left"></i>'
                },
                // change table language
                // check documentation https://datatables.net/plug-ins/i18n/
                // "url": '//cdn.datatables.net/plug-ins/1.13.3/i18n/hy.json',
            },
            "order": [[$('th.default-sort').index(), 'desc']],
            "columnDefs": [
                {
                    "targets": 'no-sort',
                    "orderable": false,
                }
            ],
            buttons: [ //https://datatables.net/extensions/buttons/examples/initialisation/export.html
                // 'csv', 'excel',
                {
                    extend: 'csv',
                    text: 'Download Excel',
                    className: 'text-primary',
                    tag: 'a'
                }
            ]
        }
    );
    var info = table.page.info();
    if (info !== undefined && info.pages > 1) {
        $('.dataTables_paginate').addClass('active')
    }
    $('.received_filter').on('keyup search', function (e) {
        //var table = $('#received').dataTable();
        if (this.value) {
            table.search(this.value).draw();
        } else {
            table.search('');
            table.columns().search('').draw();
        }
    });

    table.on('page.dt', function () {
        table.draw('page');
        dataBackgroundImage();

    }).on('order.dt', function () {
        dataBackgroundImage();
    });

    $('.dataable').DataTable({
        paging: false,
        ordering: false,
        info: false,
        searching: false,
        "scrollX": true,
    });


    if ($('.dataTables_filter').length > 0) {
        var search_inp = $('.dataTables_filter').find('input[type="search"]');
        if (search_inp.val() != '') {
            search_inp.parent().addClass('active');
        }
    }

    jQuery('body').on('input', '.dataTables_filter input[type="search"]', function (e) {
        var c = $(this);
        if (c.val() != '') {
            c.closest('.dataTables_filter').addClass('in-use');
        } else {
            c.closest('.dataTables_filter').removeClass('in-use');
        }
    });
    jQuery('body').on('blur', '.dataTables_filter input[type="search"]', function (e) {
        var c = $(this);
        c.closest('.dataTables_filter').removeClass('in-use');
        if (c.val() != '') {
            c.parent().addClass('active');
        } else {
            c.parent().removeClass('active');
        }
    })
    jQuery('body').on('focus', '.dataTables_filter input[type="search"]', function (e) {
        var c = $(this);
        if (c.val() != '') {
            c.closest('.dataTables_filter').addClass('in-use');
        }
        c.parent().removeClass('active');
    })

    $('.packages-nav li, .table-nav li').on('click', function (e) {
        if ($(this).hasClass('active')) {
            e.preventDefault();
        }
        if ($(this).closest('ul').hasClass('collapsed')) {
            $(this).closest('ul').removeClass('collapsed')
        } else {
            $(this).closest('ul').addClass('collapsed')
        }
    })

    $('#buy-instead-table tbody').on('click', '.more-info-btn', function () {
        var tr = $(this).closest('tr');
        var childTr = tr.next('.more-info');
        var lessText = $(this).data('less')
        var moreText = $(this).data('more')
        if (childTr.length > 0 && childTr.hasClass('hidden')) {
            // This row is already open - close it
            $(this).text(lessText)
            childTr.show();
            childTr.removeClass('hidden');
        } else {
            $(this).text(moreText)
            // Open this row
            childTr.hide();
            childTr.addClass('hidden');
        }
    });

    //modal
    const calcModal = document.getElementById('calcModal')
    calcModal.addEventListener('hidden.bs.modal', event => {
        // do something...
        $(event.target).find('.countries ul').removeClass('collapsed')
    })
    //input numbers custom styles
    jQuery('<div class="quantity-nav"><button class="quantity-button quantity-up"><i class="fas fa-caret-up"></i></button><button class="quantity-button quantity-down"><i class="fas fa-caret-down"></i></button></div>').insertAfter('.quantity input');
    jQuery('.quantity').each(function () {
        var spinner = jQuery(this),
            input = spinner.find('input[type="number"]'),
            btnUp = spinner.find('.quantity-up'),
            btnDown = spinner.find('.quantity-down'),
            min = input.attr('min'),
            max = input.attr('max');
        if (typeof min == 'undefined') {
            min = 0
        }
        btnUp.click(function (e) {
            e.preventDefault();
            var oldValue = parseFloat(input.val());
            if (!input.val()) {
                oldValue = 0;
            }

            if (max && oldValue >= max) {
                var newVal = oldValue;
            } else {
                var newVal = oldValue + 1;
            }
            spinner.find("input").val(newVal);
            spinner.find("input").trigger("change");
        });

        btnDown.click(function (e) {
            e.preventDefault();
            var oldValue = parseFloat(input.val());
            if (!input.val()) {
                oldValue = 0;
            }
            if (oldValue <= min) {
                var newVal = oldValue;
            } else {
                var newVal = oldValue - 1;
            }
            // if(newVal == 0 ){
            //     newVal = "";
            // }
            spinner.find("input").val(newVal);
            spinner.find("input").trigger("change");
        });
        // input.on('change input', function(e){
        //     if($(this).val() <= 0 ){
        //         e.preventDefault();
        //         $(this).val("")
        //     }
        // })
    });

    var max_fields = 10;
    var wrapper = $(".dynamic_fields_wrap");
    var add_button = $(".add_field_button");
    var x = 1;
    $(add_button).click(function (e) {
        e.preventDefault();
        if (x < max_fields) {
            x++;
            $(wrapper).append(`
          <div class="row g-2 my-3">
            <div class="col-lg-4">
                <input class="form-control" type="text" name="product_name[]" autocomplete="off">
            </div>
            <div class="col-lg-4">
                <input class="form-control" type="url" name="product_url[]" autocomplete="off">
            </div>
            <div class="col-lg-3">
                <textarea name="product_desc[]" class="form-control" maxlength="190" cols="30" rows="10"></textarea>
            </div>
            <div class="col-lg-1">
              <button type="button" class="btn btn-dark remove_field p-0 py-2 w-100 h-100 fs-4"><i class="fas fa-times"></i></button>
            </div>
          </div>`);
        }
    });
    $(wrapper).on("click", ".remove_field", function (e) {
            e.preventDefault();
            $(this).closest('div.row').remove();
            x--;
        }
    )

    // store filters

    // search
    $("form#searchStore").submit(function (e) {
        e.preventDefault()
        let search = $(this).find('input').val()
        let params = new URLSearchParams(location.search);
        params.set('search', search);
        window.history.replaceState({}, "", decodeURIComponent(`${location.pathname}?${params}`))
        location.reload();
    })
    //country
    $("#country-filter input").change(function (e) {
        let country = $(this).val()
        let params = new URLSearchParams(location.search);
        params.set('country', country);
        window.history.replaceState({}, "", decodeURIComponent(`${location.pathname}?${params}`))
        location.reload();
    })
    // starts
    $("form#stars input").click(function (e) {
        let params = new URLSearchParams(location.search);
        if ($(this).is(":checked")) {
            params.set('star', $(this).val());
            window.history.replaceState({}, "", decodeURIComponent(`${location.pathname}?${params}`))
            location.reload();
        } else {
            params.delete('star', $(this).val());
            window.history.replaceState({}, "", decodeURIComponent(`${location.pathname}?${params}`))
            location.reload();
        }

    })
    // category
    $("form#category input").click(function (e) {
        let params = new URLSearchParams(location.search);
        if ($(this).is(":checked")) {
            params.set(`category${$(this).val()}`, $(this).val());
            window.history.replaceState({}, "", decodeURIComponent(`${location.pathname}?${params}`))
            location.reload();
        } else {
            params.delete(`category${$(this).val()}`, $(this).val());
            window.history.replaceState({}, "", decodeURIComponent(`${location.pathname}?${params}`))
            location.reload();
        }
    })

    $(".accordion-collapse").each(function () {
        this.addEventListener('show.bs.collapse', function () {
            // do something...
            $('.accordion-item').removeClass('opened');
            $(this).closest('.accordion-item').addClass('opened');
        })
    })

    // set user ip network address
    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    // Mobile heade styles
    function checkWidth() {
        var windowSize = $(window).width();
        var mobile_open = $('.mobile_open');
        var header_right = $('.header_right');
        if (windowSize <= 575) {
            mobile_open.css('max-width', header_right.width())
        } else {
            mobile_open.removeAttr('style')
        }
    }

    // Execute on load
    checkWidth();
    // Bind event listener
    $(window).resize(checkWidth);

    /*$.get("https://ipinfo.io", function (response) {
        localStorage.setItem("ipUser", response.ip);
        let ipAddress = localStorage.getItem('ipUser')
        setCookie('ipAddressUser', ipAddress, 7);
    }, "json")*/


});
