$(document).ready(function () {
    let length, width, height, weight, cur_weight, aspect_ratio, price_kg, price_kg_volumetric, kg_volumetric,
        currency_code, sum, extraweight = false, result

    // Modal form countries selector
    $('form#calcForm .choose_type input').change(function() {
        $("form#calcForm #length").val('')
        $("form#calcForm #width").val('')
        $("form#calcForm #height").val('')
        $("form#calcForm #weight").val('')
        $("form#calcForm input#extraweight").prop('checked', false)
        $(".extraweight-description").hide();
        $(".calculated-total").text('')
        let shipping_method = $( 'input[name=shipping_method]:checked' ).val();
        if(shipping_method === 'standard') {
            $("form#calcForm .countries ul li.express").addClass('dis').removeClass('active')
            $("form#calcForm .countries ul li.standard").first().addClass('active')
            $("form#calcForm .countries ul li.standard").removeClass('dis')
        } else {
            $("form#calcForm .countries ul li.standard").addClass('dis').removeClass('active')
            $("form#calcForm .countries ul li.express").first().addClass('active')
            $("form#calcForm .countries ul li.express").removeClass('dis')
        }
    });

    $(".countries .form-check-input").on('click', function (e) {
        $("form#calcForm #length").val('')
        $("form#calcForm #width").val('')
        $("form#calcForm #height").val('')
        $("form#calcForm #weight").val('')
        $("form#calcForm input#extraweight").prop('checked', false)
        $(".extraweight-description").hide();
        $(".calculated-total").text('')
        if ($(this).closest('li').hasClass('active')) {
            e.preventDefault();
        }
        if ($(this).is(':checked')) {
            if($(this).data('currency_code') !== undefined) {
                $("#detailsCalc").removeClass('d-none')
            } else {
                $("#detailsCalc").addClass('d-none')
            }

            $(this).closest('.countries').find('li').removeClass('active')
            $(this).closest('li').addClass('active')
            $(document).find("form#calcForm .weight_aspect_ratio span.weight").text($(this).data('weight'))
            $(document).find("form#calcForm .weight_aspect_ratio span.aspect_ratio").text($(this).data('aspect_ratio'))
            $(document).find("form#calcForm .weight_aspect_ratio span.price_kg_volumetric").text($(this).data('price_kg_volumetric') + ' ' + $(this).data('currency_code'))
        }
        if ($(this).closest('ul').hasClass('collapsed')) {
            $(this).closest('ul').removeClass('collapsed')
        } else {
            $(this).closest('ul').addClass('collapsed')
        }
    })

    /*$("#extraweight").click(function () {
        if ($(this).is(":checked")) {
            $(".extraweight-description").show();
        } else {
            $(".extraweight-description").hide();
        }
        $("form#calcForm #length").val('')
        $("form#calcForm #width").val('')
        $("form#calcForm #height").val('')
        $("form#calcForm #weight").val('')
        $(".calculated-total").text('')
    });*/

    $("#extraweight").change(function (e) {
        $("form#calcForm #length").val('')
        $("form#calcForm #width").val('')
        $("form#calcForm #height").val('')
        $("form#calcForm #weight").val('')
        $(".calculated-total").text('')
        if ($("#extraweight:checked").length) {
            extraweight = true
            $(".extraweight-description").show();
            cur_weight = $(document).find("form#calcForm .countries li.active input").data('weight')
            aspect_ratio = $(document).find("form#calcForm .countries li.active input").data('aspect_ratio')
            price_kg = $(document).find("form#calcForm .countries li.active input").data('price_kg')
            currency_code = $(document).find("form#calcForm .countries li.active input").data('currency_code')
            price_kg_volumetric = $(document).find("form#calcForm .countries li.active input").data('price_kg_volumetric')
            $("form#calcForm .weight_aspect_ratio span.weight").text(cur_weight)
            $("form#calcForm .weight_aspect_ratio span.aspect_ratio").text(aspect_ratio)
            $("form#calcForm .weight_aspect_ratio span.price_kg_volumetric").text(price_kg_volumetric + ' ' + currency_code)
        } else {
            extraweight = false
            $(".extraweight-description").hide();
        }
    })

    $("#calcForm #length, #calcForm #width, #calcForm #height, #calcForm #weight").on("keyup change", function (e) {
        length = $("#calcForm #length").val()
        width = $("#calcForm #width").val()
        height = $("#calcForm #height").val()
        weight = $("#calcForm #weight").val()

        cur_weight = $(document).find("form#calcForm .countries li.active input").data('weight')
        aspect_ratio = $(document).find("form#calcForm .countries li.active input").data('aspect_ratio')
        price_kg = $(document).find("form#calcForm .countries li.active input").data('price_kg')
        currency_code = $(document).find("form#calcForm .countries li.active input").data('currency_code')

        if (extraweight) { // volumetric | checkbox is true
            sum = parseFloat(length) + parseFloat(width) + parseFloat(height);
            if (weight > 0) {
                if (sum > cur_weight) {
                    kg_volumetric = (parseFloat(length) * parseFloat(width) * parseFloat(height)) / aspect_ratio
                    if (kg_volumetric / 2 > weight) {
                        result = kg_volumetric * price_kg_volumetric
                    } else {
                        result = weight * price_kg
                    }
                } else {
                    result = weight * price_kg;
                }
            } else {
                result = '----'
            }
        } else {
            if (weight > 0) {
                result = weight * price_kg;
            } else {
                result = '----'
            }
        }
        if(!isNaN(result)) {
            $(".calculated-total").text(Number(result).toFixed(0) + ' ' + currency_code)
        } else {
            $(".calculated-total").text('---' + currency_code)
        }
    })
});
